<template>
  <div id="portfolio">
    <div class="portfolio-container">
      <div class="portfolio-types">
        <div v-for="tag in tags" class="types"><a href="#" @click="filterTag(tag)"
                                                  :class="{active: tag.active}">{{ tag.name }}</a></div>
      </div>
      <div class="portfolio-grid">
        <router-link :to="{name: 'portfolio_project', params: {project: project.code}}"
                     v-for="project in displayedProjects"
                     class="card">
          <img :src="getImagePath(project.main.path)" alt="Card image"/>
          <div class="overlay">
            <span class="title">{{ project.title }}</span>
            <span class="subtitle">{{ project.subtitle }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import projects from "@/http/projects";
import tags from "@/http/tags";

export default {
  name: "Portfolio",
  data() {
    return {
      projects: [],
      displayedProjects: [],
      tags: []
    }
  },
  methods: {
    getImagePath(picture) {
      return (process.env.NODE_ENV === "production" ? "/" : "http://localhost:8000/") + picture;
    },
    filterTag(tag) {
      if (tag.active) {
        tag.active = false;
        this.displayedProjects = this.projects;
      } else {
        let t = this.tags.find(tag => tag.active);
        if (t) t.active = false;
        tag.active = true;
        this.displayedProjects = [];
        this.projects.forEach(project => {
          for (let i = 0; i < project.pictures.length; i++) {
            if (project.pictures[i].tags.includes(tag.name)) {
              this.displayedProjects.push(project);
              break;
            }
          }
        });
      }
    }
  },
  created() {
    projects.findAll().then(value => {
      this.projects = value.data;
      this.displayedProjects = this.projects;
    });
    tags.findAll().then(value => {
      value.data.forEach(tag => {
        this.tags.push({name: tag, active: false});
      });
    })
  }
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  div#portfolio {
    & div.portfolio-container {
      & div.portfolio-grid {
        & a.card {
          @size: 50%;
          @margin: 5px;
          width: calc(@size - @margin) !important;
          height: 0 !important;
          padding-bottom: calc(@size - @margin);
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  div#portfolio {
    & div.portfolio-container {
      & div.portfolio-grid {
        & a.card {
          width: 100% !important;
          height: 0 !important;
          padding-bottom: 100%;
        }
      }
    }
  }
}

div#portfolio {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 2000px;
  margin: auto;

  & div.portfolio-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    & div.portfolio-types {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px 0;

      & div.types {
        margin: 7px;

        & a {
          display: block;
          padding: 10px 15px;
          border-radius: 100px;
          background-color: rgba(200, 200, 200, .8);
          color: black;
          font-size: .9em;
          text-decoration: none;
          transition: .3s;

          &:hover {
            background-color: rgba(190, 190, 190, 1);
          }

          &.active {
            background-color: rgba(150, 150, 150, .9);
          }
        }
      }
    }

    & div.portfolio-grid {
      @margin: 5px;
      @size: 250px;

      @media all and (min-width: 1300px) {
        @size: 300px;
        justify-content: center;

        & a.card {
          width: @size !important;
          height: @size !important;
          margin: @margin !important;
        }
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      & a.card {
        position: relative;
        width: @size;
        height: @size;
        margin: @margin auto;
        background-color: rgba(180, 180, 180, .7);
        overflow: hidden;
        text-decoration: none;

        &:hover div.overlay {
          opacity: 1;
        }

        & img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        & div.overlay {
          @media all and (max-width: 800px) {
            height: 100%;
          }

          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          opacity: 0;
          top: 0;
          left: 0;
          width: inherit;
          height: inherit;
          background-color: rgba(150, 150, 150, .7);
          transition: opacity .2s;

          & span {
            color: white;
            text-align: center;

            &.title {
              font-size: 2.5em;
              text-transform: uppercase;
              font-weight: bold;
            }

            &.subtitle {
              font-size: .9em;
            }
          }
        }
      }
    }
  }
}
</style>
