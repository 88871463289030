import http from "@/http/config";

const TAGS_URL = "/tags";

async function findAll() {
    return await http.get(TAGS_URL);
}

export default {
    findAll
};
